@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: 'Calibri';
  src: url('./Assets/fonts/Calibri-Bold.eot');
  src: url('./Assets/fonts/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Assets/fonts/Calibri-Bold.woff2') format('woff2'),
    url('./Assets/fonts/Calibri-Bold.woff') format('woff'),
    url('./Assets/fonts/Calibri-Bold.ttf') format('truetype'),
    url('./Assets/fonts/Calibri-Bold.svg#Calibri-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('./Assets/fonts/Calibri.eot');
  src: url('./Assets/fonts/Calibri.eot?#iefix') format('embedded-opentype'),
    url('./Assets/fonts/Calibri.woff2') format('woff2'),
    url('./Assets/fonts/Calibri.woff') format('woff'),
    url('./Assets/fonts/Calibri.ttf') format('truetype'),
    url('./Assets/fonts/Calibri.svg#Calibri') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@page {
  size: Letter landscape;
  margin: 48px;
}
@media print {
  .MuiPaper-root > div {
    overflow: hidden !important;
  }
}
.gm-style-iw-chr .gm-style-iw-ch {
  padding-top: 10px;
}

button.gm-ui-hover-effect {
  visibility: hidden;
  height: 0 !important;
  opacity: 0;
}
.ck .ck.ck-balloon-panel {
  z-index: 9999;
}

.ck.ck-sticky-panel__placeholder {
  height: 0 !important;
}
